/**
 * ConsentCheckbox component that...
 *
 */
import * as React from 'react'
import { Link } from 'gatsby'
import * as consentStyles from '../../styles/consent-checkbox.module.scss'

const ConsentCheckbox = () => {
  return (
    <label className={consentStyles.checkboxWrapper}>
      <span>
        <strong>Privacy Consent</strong>
      </span>
      <br />
      <input
        id="consent"
        type="checkbox"
        name="consent"
        value="agree"
        title="I understand and consent."
        required
      />
      {` `}
      <span>
        I understand that this form collects my name and email so I that can be
        contacted. I agree to such data collection. For more information, please
        check our <Link to="/terms">privacy policy</Link>.
      </span>
    </label>
  )
}
export default ConsentCheckbox
