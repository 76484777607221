/**
 * ContactGetform component that...
 *
 */
import * as React from 'react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import axios from 'axios'
import ConsentCheckbox from './consent-checkbox'
import * as contactGfStyles from '../../styles/contact-form.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'

const ContactGetform = () => {
  const { t } = useTranslation()
  const endPoint = process.env.GF_ENDPOINT
  const siteKey = process.env.GR_SITE_KEY
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: 'post',
      url: endPoint,
      data: new FormData(form)
    })
      .then((r) => {
        handleServerResponse(true, 'Thanks!', form)
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <div className={contactGfStyles.contactformWrapper}>
      <h2>
        <Trans i18nKey="contact.title" t={t}>
          Get in Touch
        </Trans>
      </h2>
      <p>
        We would like to hear from you. Please drop us a line to find out more
        about your context and challenges, and how we can help you. Let’s make
        something awesome together!
      </p>
      <form onSubmit={handleOnSubmit}>
        <label>
          <Trans i18nKey="form.name-label" t={t}>
            Name
          </Trans>
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Enter name (required)"
            data-i18n="[placeholder]form.name-placeholder"
            required
          />
        </label>
        <label>
          <Trans i18nKey="form.email-label" t={t}>
            Email
          </Trans>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter email (required)"
            data-i18n="[placeholder]form.email-placeholder"
            required
          />
        </label>
        <label>
          <Trans i18nKey="form.msg-label" t={t}>
            Message
          </Trans>
          <textarea
            id="message"
            name="message"
            rows={5}
            placeholder="Enter message (required)"
            data-i18n="[placeholder]form.msg-placeholder"
            required
          />
        </label>
        <ConsentCheckbox />
        <br />
        <br />
        <div className="g-recaptcha" data-sitekey={siteKey} />
        <br />
        <br />
        <button type="submit" disabled={serverState.submitting}>
          <FontAwesomeIcon icon={faPaperPlane} size="1x" aria-hidden="true" />
          {` `}Send
        </button>
        {serverState.status && (
          <p className={!serverState.status.ok ? 'errorMsg' : ''}>
            {serverState.status.msg}
          </p>
        )}
      </form>
    </div>
  )
}
export default ContactGetform
