import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactGetform from '../components/forms/contact-getform'

function ContactPage() {
  return (
    <Layout>
      <Seo title="Contact" />
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
      <main className="l-main">
        <div className="l-constrained">
          <ContactGetform />
        </div>
      </main>
    </Layout>
  )
}
export default ContactPage
